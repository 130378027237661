import React from 'react';
import { headerHolder, navHolder } from './Header.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const headerVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      type: 'tween',
    },
  },
};

const ulVariants = {
  initial: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const liVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      type: 'tween',
    },
  },
};

export default function Header() {
  return (
    <>
      <motion.header className={headerHolder} variants={headerVariants} initial="hidden" animate="visible">
        <StaticImage src="../../images/logo.png" alt="Topolski Studio logo" width={80} placeholder="blurred" />
        <h1>Topolski Studio</h1>
      </motion.header>

      <nav className={navHolder}>
        <motion.ul variants={ulVariants} initial="initial" animate="visible">
          <motion.li variants={liVariants} key="about">
            <a href="#about" title="About Topolski Studio">
              About
            </a>
          </motion.li>
          <motion.li variants={liVariants} key="mailing list">
            <a href="#mailing-list" title="Join the Topolski Studio mailing list">
              Mailing List
            </a>
          </motion.li>
          <motion.li variants={liVariants} key="contact">
            <a href="#contact" title="Contact details for Topolski Studios">
              Contact
            </a>
          </motion.li>
        </motion.ul>
      </nav>
    </>
  );
}
