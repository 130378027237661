import React from 'react';
import { layoutHolder } from './Layout.module.scss';
import '../../styles/normalize.css';
import '../../styles/styles.scss';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './Header';

const mainVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    y: 20,

    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

export default function Layout({ children }) {
  return (
    <div className={`pageHolder ${layoutHolder}`}>
      <Header />
      <AnimatePresence>
        <motion.main key={children?.props?.path} variants={mainVariants} initial="initial" animate="visible" exit="exit">
          {children}
        </motion.main>
      </AnimatePresence>
    </div>
  );
}
